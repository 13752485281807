import { template as template_8494b682ce8d4a1eb4e31b7700732235 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8494b682ce8d4a1eb4e31b7700732235(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
