import { template as template_a4475fa4d5544c94a6582fe39ab5fff3 } from "@ember/template-compiler";
const FKLabel = template_a4475fa4d5544c94a6582fe39ab5fff3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
