import { template as template_38515852d3e04dea82be9337dfc487ae } from "@ember/template-compiler";
const FKText = template_38515852d3e04dea82be9337dfc487ae(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
