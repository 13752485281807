import { template as template_2825aacb5d76484da9eb91d6f9de78be } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_2825aacb5d76484da9eb91d6f9de78be(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
